// Properties
const cssContainer = '#confetti';
const cssChildContainer = '.confetti-box';
const cssRunClass = 'run-animation';

// Helper
function random(max){
    return Math.random() * (max - 0);
}


// Animation creator function
function confettiContainerClickAnimationInit() {

    document.body.addEventListener('click', e => {

        const confettiContainer = document.querySelector(`${cssContainer}`);

        confettiContainer.style.top = `${e.pageY - 25}px`;
        confettiContainer.style.left = `${e.pageX - 25}px`;

        confettiContainer.classList.add(`${cssRunClass}`);

        const newConfettiContainer = confettiContainer.cloneNode(true);
        confettiContainer.parentNode.replaceChild(newConfettiContainer, confettiContainer);

    });

}

// init argument
const confetti = {
    init() {
        const confettiFragment = document.createDocumentFragment();
        const confettiBox = document.querySelector(`${cssContainer} ${cssChildContainer}`);
        for (let i=0; i<100; i++) {
            const styles = 'transform: translate3d(' + (random(500) - 250) + 'px, ' + (random(200) - 150) + 'px, 0) rotate(' + random(360) + 'deg);background: hsla('+random(360)+',100%,50%,1);';
            const e = document.createElement("i");
            e.style.cssText = styles.toString();
            confettiBox.appendChild(e);
        }

        document.body.appendChild(confettiFragment);

        confettiContainerClickAnimationInit();
    }
}


export { confetti as confettiClickAnimation };